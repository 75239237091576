import { IWindowParams } from ".."

const initialState: IWindowParams = {
  windowHeight: 0,
  windowWidth: 0,
  browserHeight: 0,
  browserWidth: 0,
  x: 0,
  y: 0,
  scrolling: false,
}

export default initialState
