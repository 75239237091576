import React from "react"
import initialState from "./state"

const LangCtx = React.createContext({
  state: { ...initialState },
  actions: {
    setLangPl: () => {},
    setLangEn: () => {},
  },
})

export default LangCtx
