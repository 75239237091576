const initialState = {
  imBusy: false,
  items: [],
  error: false,
  coupon: "",
  unit: "PLN",
  shipping: {
    method_id: "free_shipping",
    method_title: "Darmowa wysyłka",
    total: 0,
  },
  shippingRules: {},
  shippings: [],
  orderInApi: {},
  sum: {
    val: 0,
    qty: 0,
  },

  payment: {
    msg: "Proszę czekać...",
    phase: "idle",
  },
}

export default initialState
